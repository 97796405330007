import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'
import { getCookie } from './helpers/fn'

const Cookie = styled.div`
  max-width: 460px;
  width: calc(100% - 8vw);
  padding: 0.9rem 1.2rem;
  display: flex;
  line-height: 1.4;
  align-items: center;
  justify-content: space-between;
  font-size: 88%;
  border-radius: 4px;
  box-shadow: 0 6px 30px rgba(0,0,0,0.3);
  background: white;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  svg {
    font-size: 18px;
    margin-left: 1.2rem;
    cursor: pointer;
  }
`

const CookieNotice = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(getCookie('cookies') || false)

  const handleClick = () => {
    document.cookie = `cookies=true; expires=${new Date(new Date().setFullYear(new Date().getFullYear() + 1))}`
    setCookiesAccepted(true)
  }

  return (
    <>
      {!cookiesAccepted &&
      <Cookie>
        <div>Wij gebruiken cookies om je online ervaring te verbeteren. Voor meer informatie bekijk ons <Link to='/privacy-policy'>privacy policy</Link></div>
        <FontAwesomeIcon icon={faTimes} onClick={handleClick} />
      </Cookie>
      }
    </>
  )
}

export default CookieNotice
