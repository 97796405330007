import React, { useEffect, useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import SwoofLogoLight from '../images/swoof.svg'
import SwoofLogoDark from '../images/swoof-dark.svg'
// import { ButtonAnchor }  from './styled'
import { ButtonLink }  from './styled'
import { MainNav }  from './styled/menu'
import Menu from './menu'
import { device } from './helpers/constants'
import { trackBetaCampaign } from '../helpers/campaigns'

const menuQuery = graphql`
  query MainMenu {
    allWordpressWpApiMenusMenusItems(filter: {name: {eq: "Main menu"}}) {
      edges {
        node {
          items {
            title
            url
            object_slug
            object_id
            target
            classes
            wordpress_children {
              wordpress_id
              title
              url
              object_slug
              object_id
              target
              classes
            }
          }
        }
      }
    }
  }
`

const FlexCenter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.laptop} {
    width: auto;
    justify-content: flex-start;
  }
`

const Buttons = styled(FlexCenter)`
  a {
    &:not([class]) {
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 40px;
    } 
  }
`

const Logo = styled.img`
  max-width: 80px;
  min-width: 40px;
  height: auto;
  margin-bottom: 0;
  line-height: 0;
  margin-right: 40px;
  display: block;
`

const MobileMenuWrap = styled.div`
  width: 100vw;
  height: calc(100vh - 68px);
  position: fixed;
  top: 69px;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 4vw;
  transform: translateX(100%);
  will-change: transform;
  transition: transform 0.25s;
  background: white;
  border-top-left-radius: ${props => !props.menuBg && `36px`};

  &.is-active {
    transform: translateX(0);
  }
`

const Hamburger = styled.div`
  height: 20px;
  width: 20px;
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    background: ${props => props.menuBg ? `black` : `white`};;
    top: 50%;
    transition: transform 0.25s;
  }

  &:before {
    transform: translateY(calc(-50% - 4px));
  }
  &:after {
    transform: translateY(calc(-50% + 4px));
  }

  &.is-active {
    &:before {
      transform: translateY(-50%) rotate(45deg);
    }
    &:after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
`

const AppButtons = () => (
  <Buttons>
    {/* <ButtonAnchor href={`${process.env.GATSBY_APP_URL}/signin/signup`} target="_blank" rel="noopener noreferrer">Aanmelden</ButtonAnchor> */}
    <ButtonLink onClick={() => trackBetaCampaign('Menu Button')} to="/beta">Aanmelden</ButtonLink>
    {/* <a href={`${process.env.GATSBY_APP_URL}/signin`} target="_blank" rel="noopener noreferrer">Inloggen</a> */}
  </Buttons>
)

const MainMenu = ({ data, menuBg }) => {
  const { items } = data.allWordpressWpApiMenusMenusItems.edges[0].node
  const [hamburgerMenu, setHamburgerMenu] = useState(null)
  const [showMenu, setShowMenu] = useState(false)

  const windowResize = () => {
    window.innerWidth < 1024 ? setHamburgerMenu(true) : setHamburgerMenu(false)
  }

  useEffect(() => {
    windowResize()
    window.addEventListener('resize', windowResize)

    return () => {
      window.removeEventListener('resize', windowResize)
    }
  }, [])

  return (
    <>
      {!hamburgerMenu ? (
        <MainNav menuBg={menuBg}>
          <FlexCenter>
            <Link to="/">
              <Logo src={!menuBg ? SwoofLogoLight : SwoofLogoDark} alt="Swoof"/>
            </Link>
            <Menu items={items} type="horizontal" />
          </FlexCenter>

          <AppButtons menuBg={menuBg} />
        </MainNav>
      ) : (
        <MainNav menuBg={menuBg}>
          <FlexCenter>
            <Link to="/">
              <Logo src={!menuBg ? SwoofLogoLight : SwoofLogoDark} alt="Swoof"/>
            </Link>
            <Hamburger
              menuBg={menuBg}
              onClick={() => setShowMenu(!showMenu)}
              className={showMenu && 'is-active'} 
            />
          </FlexCenter>

          <MobileMenuWrap menuBg={menuBg} className={showMenu && 'is-active'}>
            <AppButtons />
            <Menu items={items} type="mobile" />
          </MobileMenuWrap>
        </MainNav>
      )}
    </>
  )
}

export default props => (
  <StaticQuery
    query={menuQuery}
    render={data => <MainMenu data={data} {...props} />}
  />
)
