import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import SwoofOg from '../images/swoof-og.png'

function SEO({ description, lang, meta, title, yoastMeta }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            description
          }
        }
      }
    `
  )

  const metaTitle = yoastMeta ? yoastMeta.yoast_wpseo_title || title : title
  const metaDescription = yoastMeta ? yoastMeta.yoast_wpseo_metadesc || description || site.siteMetadata.description : description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
          rel: `canonical`,
          href: yoastMeta && yoastMeta.yoast_wpseo_canonical
        }
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `og:locale`,
          content: `nl_NL`,
        },
        {
          property: `og:title`,
          content: yoastMeta ? yoastMeta.yoast_wpseo_facebook_title || metaTitle : metaTitle,
        },
        {
          property: `og:description`,
          content: yoastMeta ? yoastMeta.yoast_wpseo_facebook_description || metaDescription : metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `og:image`,
          content: yoastMeta && yoastMeta.yoast_wpseo_facebook_image && yoastMeta.yoast_wpseo_facebook_image.localFile ? `${process.env.ROOT_URL}${yoastMeta.yoast_wpseo_facebook_image.localFile.childImageSharp.fixed.src}` : `${process.env.ROOT_URL}${SwoofOg}`,
        },
        {
          name: `twitter:card`,
          content: yoastMeta && yoastMeta.yoast_wpseo_twitter_image ? `summary_large_image` : `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: yoastMeta ? yoastMeta.yoast_wpseo_twitter_title || metaTitle : metaTitle,
        },
        {
          name: `twitter:description`,
          content: yoastMeta ? yoastMeta.yoast_wpseo_twitter_description || metaDescription : metaDescription,
        },
        {
          name: `twitter:image`,
          content: yoastMeta && yoastMeta.yoast_wpseo_twitter_image && yoastMeta.yoast_wpseo_twitter_image.localFile ? `${process.env.ROOT_URL}${yoastMeta.yoast_wpseo_twitter_image.localFile.childImageSharp.fixed.src}` : `${process.env.ROOT_URL}${SwoofOg}`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `nl`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
