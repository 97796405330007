import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import noice from '../../images/noice.png'
import { device } from '../helpers/constants'

const SectionStyles = css`
  margin-bottom: 8vw;
  padding: ${props => props.bg ? '8vw' : null};
  background-color: ${props => props.bg ? props.bg : null};

  * {
    color: ${props => props.color ? props.color : null};
  }

  @media ${device.tablet} {
    padding: ${props => props.bg ? '6.0rem 4.0rem' : null};
  }
`

export const Section = styled.section`
  ${SectionStyles}
  position: relative;
  border-radius: ${props => props.bg ? '8px' : null};

  /* &.bg:after {
    content: '';
    position: absolute;
    height: 80%;
    top: 50%;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    background: #ECEEF1;
    z-index: -1;
    transform: skewY(-12deg) translateY(-50%);
  } */
`

export const SectionCenter = styled(Section)`
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  /* & + ${Section} {
    margin-top: calc(-8vw + 1.45em);
  } */
`
export const Button = css`
  color: white !important;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  border-radius: 24px;
  text-decoration: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
  background-image: url(${noice}), linear-gradient(to right, #F1698D, #D277EF);
  box-shadow: 0 2px 4px rgba(0,0,0, 0.25);
  transition: opacity 0.25s;
  transform: translateZ(0);

  &:hover {
    opacity: 0.9;
  }
`

export const ButtonAnchor = styled.a`
  ${Button}
`

export const ButtonLink = styled(Link)`
  ${Button}
`

export const FullWidth = styled.div`
  max-width: 100vw;
  margin-left: calc(-100vw / 2 + 100% / 2);
  margin-right: calc(-100vw / 2 + 100% / 2);
  ${SectionStyles}

  ${Section} {
    margin-bottom: 0;
  }

  > * {
    max-width: 1220px;
    margin: 0 auto;
  }

  @media ${device.tablet} {
    padding: ${props => props.bg ? '10.0rem 4.0rem' : null};
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 2.4rem;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid rgba(236, 238, 240, 1);

    @media ${device.tablet} {
      padding-bottom: 0;
      border: none;
      margin-bottom: 8vw;
    }
  }

  video,
  img {
    max-width: 100%;
  }

  @media ${props => props.mq ? props.mq: device.tablet} {
    flex-direction: row;
    align-items: ${props => props.alignItems ? props.alignItems : `center` };

    > * {
      width: 50%;

      &:first-child {
        margin-right: 2vw;
      }

      &:last-child {
        margin-left: 2vw;
      }

      p:last-child, footer {
        margin-bottom: 0;
      }
    }
  }
`