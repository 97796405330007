import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faFileInvoice, faCreditCard, faAddressBook, faShapes, faChartLine, faEye } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { Link } from 'gatsby'
import { HoriontalMenu, SubMenu, MobileMenu, Item, SubMenuToggle } from './styled/menu'

library.add(
  faFileInvoice,
  faCreditCard,
  faAddressBook,
  faShapes,
  faChartLine,
  faEye
)

const menuTypes = {
  'horizontal': HoriontalMenu,
  'vertical': HoriontalMenu,
  'submenu': SubMenu,
  'mobile': MobileMenu
}

export const MenuItem = ({ item, menuType, parent }) => {
  if (item.url === '#' && menuType === 'mobile') {
    return (
      item.wordpress_children 
        ? item.wordpress_children.map(item => <MenuItem key={item.object_id} item={item} menuType="mobile" />)
        : null
    )
  }

  return (
    <Item>
      {!item.target
        ? item.url !== '#'
          ? <Link
              to={parent && parent.url !== '#' ? `/${parent.object_slug}/${item.object_slug}` : `/${item.object_slug}`}
              activeClassName="is-active"
            >
            {item.classes && <FontAwesomeIcon icon={item.classes} />}
            {item.title}
            {item.wordpress_children && menuType !== 'mobile' && <FontAwesomeIcon icon={faChevronDown} />}
          </Link>
          : <SubMenuToggle>
              {item.title}
              <FontAwesomeIcon icon={faChevronDown} />
            </SubMenuToggle> 
        : <a target={item.target} href={item.url}>{item.title}</a>
      }
      {item.wordpress_children && menuType !== 'mobile' ? <Menu items={item.wordpress_children} parent={item} type="submenu" /> : null }
    </Item>
  )
}

const Menu = ({ items, type, menuBg, parent }) => {
  const MenuType = menuTypes[type]

  return (
    <MenuType menuBg={menuBg}>
      {items && items.map(item => <MenuItem key={item.object_id} item={item} menuType={type} parent={parent} />)}
    </MenuType>
  )
}

export default Menu