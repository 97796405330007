import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export const trackBetaCampaign = category => {
  trackCustomEvent({
    category,
    action: 'Click',
    label: 'Join Beta Campaign',
  })
}

export const trackFaqCampaign = (action, label) => {
  trackCustomEvent({
    category: 'FAQ',
    action,
    label,
  })
}