import styled from 'styled-components'
import { device } from '../helpers/constants'

export const Item = styled.li`
  white-space: pre;
  position: relative;
  backface-visibility: hidden;
  /* -webkit-backface-visibility: hidden; */

  a {
    color: inherit;
    font-weight: 400;
    text-decoration: none;
    display: block;
    display: flex;
    align-items: center;
    /* transition: opacity 0.25s; */

    &.is-active {
      /* font-weight: 600; */
      opacity: 0.6;
    }

    &:hover {
      opacity: 0.6;
    }

    > .svg-inline--fa {
      width: 20px;
      text-align: center;
      font-size: 16px;
      margin-left: 0;
      margin-right: 8px;
    }
  }

 .svg-inline--fa {
    opacity: 0.4;
    margin-left: 8px;
  }

  &:hover {
    & > span {
      opacity: 0.7;
    }
  }
`

export const SubMenuToggle = styled.span`
  color: inherit;
  cursor: default;
  display: flex;
  align-items: center;
  /* transition: opacity 0.25s; */
`

const Menu = styled.ul`
  list-style: none;
  margin: 0;
  /* color: white; */
  /* font-weight: 400; */
`

export const SubMenu = styled(Menu)`
  min-width: 280px;
  color: #000C26;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 58px;
  transform: translate(-50%, 12px);
  padding: 0.6rem 2.4rem;
  border-radius: 4px;
  background: white;
  box-shadow: 0 3px 30px rgba(0,0,0, 0.1);
  transition: 0.25s;

  & > ${Item} {
    margin-bottom: 0;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(236, 238, 240, 1);;
    }

    a {
      padding: 1.2rem 0;
    }
  }
`

export const HoriontalMenu = styled(Menu)`
  height: 68px;
  display: flex;
  align-items: center;
  color: inherit;

  & > ${Item} {
    height: 100%;
    padding: 0 20px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    transform: translate3d( 0, 0, 0);

    &:hover > ${SubMenu} {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, 0);
    }
  }
`

export const MobileMenu = styled(Menu)`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(236, 238, 240, 1);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${Item} {

    &:not(:last-child) {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(236, 238, 240, 1);
    }
  }
`

export const MainNav = styled.nav`
  height: 68px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  color: #000C26;
  display: flex;
  display: flex;
  padding: 0 4vw;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  background: ${props => props.menuBg ? `white` : `linear-gradient(to bottom, rgba(0,0,0, 0.5), rgba(0,0,0, 0))`};
  box-shadow: 0 1px 0 0 ${props => props.menuBg ? `rgba(236, 238, 240, 1)` : `rgba(236, 238, 240, 0)`};
  transition: background 0.25s, box-shadow 0.25s, color 0.25s;

  @media ${device.laptop} {
    padding: 0 2vw;
    color: ${props => props.menuBg ? `#000C26` : `white`};
  }
`