import React from 'react'
import styled from 'styled-components'
import { device } from './helpers/constants'
import Footer from './footer'
import CookieNotice from './cookies'

import './layout.css'

const LayoutWrap = styled.div`
  margin: 0 auto;
  padding-top: calc(68px + 8vw);
  max-width: ${props => props.template ? props.template === 'wide_page' ? 1220 : 680 : 1220 }px;
  width: 92vw;

  @media ${device.laptop} {
    padding-top: calc(68px + 4vw);
    width: 96vw;
  }
`

const Main = styled.main`
  margin-bottom: 8vw;

  @media ${device.laptop} {
    margin-bottom: 4vw;
  }
`

const Layout = ({ children, template = 'wide_page' }) => (
  <>
    <LayoutWrap template={template}>
      <Main>
        {children}
      </Main>
    </LayoutWrap>

    <Footer />

    <CookieNotice />
  </>
)

export default Layout
