import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { device } from './helpers/constants'
import SwoofLogoLight from '../images/swoof.svg'
import { trackBetaCampaign } from '../helpers/campaigns'

const FooterWrap = styled.footer`
  /* background: #f7f8f9; */
  color: white;
  background: #18181E;
  /* background: #000c26; */

  a {
    color: #8B8D90;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }
`

const FooterInner = styled.div`
  padding: 4vw 0;
  margin: 0 auto;
  max-width: 1220px;
  width: 92vw;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #414144;
  margin-bottom: 2.4rem;

  @media ${device.laptop} {
    width: 96vw;
    justify-content: space-between;
  }
`

const Menu = styled.ul`
  list-style: none;
  margin: 0;

  &:first-child {
    min-width: 100%;

    @media ${device.tablet} {
      min-width: auto;
    }
  }

  &:not(:last-child) {
    margin-bottom: 8vw;
    flex-basis: 50%;
    padding-right: 2vw;

    @media ${device.laptop} {
      flex-basis: auto;
      margin-bottom: 0;
    }
  }
`

const Social = styled.div`
  display: flex;

  a {
    &:not(:last-child) {
      margin-right: 2.0rem;
    }
  }

  .svg-inline--fa {
    font-size: 1em;
  }
`

const Logo = styled.img`
  max-width: 80px;
  min-width: 40px;
  height: auto;
  margin-bottom: 0;
  line-height: 0;
  margin-right: 40px;
  display: block;
`

const LegalMenuWrap = styled.div`
  width: 92vw;
  max-width: 1220px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  font-size: 80%;
  padding-bottom: 2.4rem;

  @media ${device.laptop} {
    width: 96vw;
  }

  > div {
    color: #8B8D90;

    &:not(:last-child) {
      margin-right: 2.4rem;
    }
  }
`

const LegalMenu = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;

  li {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 1.2rem;

      @media ${device.tablet} {
        margin-bottom: 0;
        margin-right: 1.2rem;
      }
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
  }
`

const Footer = () => (
  <FooterWrap>
    <FooterInner>
      <Menu>
        <li><Link to="/"><Logo src={SwoofLogoLight} alt="Swoof"/></Link></li>
      </Menu>

      <Menu>
        <li><strong>Product</strong></li>
        <li><Link to="/facturen">Facturen</Link></li>
        {/* <li><Link to="/betalingen">Betalingen via iDEAL</Link></li> */}
        <li><Link to="/contacten">Contacten</Link></li>
        <li><Link to="/producten">Producten</Link></li>
        <li><Link to="/statistieken">Heldere statistieken</Link></li>
        <li><Link to="/inzichten">Duidelijke inzichten</Link></li>
      </Menu>

      <Menu>
        <li><strong>Meer</strong></li>
        <li><Link to="/over-ons">Over ons</Link></li>
        <li><Link to="/voor-wie">Voor wie</Link></li>
        <li><Link to="/veelgestelde-vragen">Veelgestelde vragen</Link></li>
        <li><Link to="/tarieven">Tarieven</Link></li>
      </Menu>

      <Menu>
        <li><strong>Resources</strong></li>
        <li><Link onClick={() => trackBetaCampaign('Site Footer')} to="/beta">Aanmelden beta</Link></li>
        <li><Link to="/mogelijkheden">Mogelijkheden</Link></li>
        {/* <li><a href={`${process.env.GATSBY_APP_URL}/signin`} target="_blank" rel="noopener noreferrer">Inloggen beta</a></li> */}
        {/* <li><Link to="/demos">Demo's</Link></li> */}
        {/* <li><Link to="/help-center">Help center</Link></li> */}
        {/* <li><Link to="/helpdesk">Helpdesk</Link></li> */}
      </Menu>

      <Menu>
        <li><strong>Connect</strong></li>
        <li>
          <Social>
            <a target="_blank" rel="noopener noreferrer" title="Facebook" href="https://www.facebook.com/swoof.me/"><FontAwesomeIcon icon={faFacebook} /></a>
            <a target="_blank" rel="noopener noreferrer" title="Instagram" href="https://www.instagram.com/swoof.me/"><FontAwesomeIcon icon={faInstagram} /></a>
            <a target="_blank" rel="noopener noreferrer" title="Twitter" href="https://twitter.com/SwoofMe"><FontAwesomeIcon icon={faTwitter} /></a>
            <a target="_blank" rel="noopener noreferrer" title="YouTube" href=" https://www.youtube.com/channel/UC3HnmQOnIKbDIgFRljfyIGw"><FontAwesomeIcon icon={faYoutube} /></a>
            <a target="_blank" rel="noopener noreferrer" title="Linkedin" href="https://www.linkedin.com/company/swoof/"><FontAwesomeIcon icon={faLinkedin} /></a>
          </Social>
        </li>
      </Menu>
    </FooterInner>

    <LegalMenuWrap>
      <div>© <Link to="/">Swoof</Link> {new Date().getFullYear()}</div>

      <LegalMenu>
        <li><Link to="/privacy-policy">Privacy policy</Link></li>
        <li><Link to="/algemene-voorwaarden">Algemene voorwaarden</Link></li>
        <li><Link to="/cookieverklaring">Cookieverklaring</Link></li>
        {/* <li><Link to="/pers">Pers</Link></li> */}
        {/* <li><Link to="/boekhouders-en-accountants">Boekhouders en accountants</Link></li> */}
      </LegalMenu>
    </LegalMenuWrap>
  </FooterWrap>
)

export default Footer